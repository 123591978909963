/* DiscountCode Edit, Vue Component */
<template>
  <v-card v-if="entity && !loading" id="discountcode-edit" class="entity-edit">
      <v-card-title>Edit Discount Code</v-card-title>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-alert
          v-if="serverErrorMessage"
          type="error"
        >
          {{ serverErrorMessage }}
        </v-alert>
      </v-card-text>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-row class="align-center pb-5">
        <v-col>
        <v-btn
          color="primary"
          class="me-3"
          @click="Save"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiContentSaveOutline  }}
          </v-icon>
          <span>Save</span>
        </v-btn>
        </v-col>
        <v-col class="text-right">
        <v-btn
          color="secondary"
          class="me-3"
          :to="{ name: 'discountcode-view', params: { id: entity.Id } }"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiClose }}
          </v-icon>
          <span>Cancel</span>
        </v-btn>
        </v-col>
      </v-row>
      </v-card-text>

      <v-form ref="form">
      <v-simple-table dense>
        <thead>
          <tr>
            <th>Field</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>

      <tr>
        <td>Code</td>
        <td><v-text-field
      label="Code"
      solo-inverted
      flat
      :error-messages="serverErrors.filter(x => x.field === 'Code').map(x => x.error)"
      v-model="entity.Code"
      :rules="[validators.required]"
    ></v-text-field>
</td>
      </tr>

      <tr>
        <td>Enabled</td>
        <td><v-checkbox v-model="entity.Enabled" :color="(entity.Enabled ? 'success' : 'secondary')"></v-checkbox></td>
      </tr>

      <tr>
        <td>Only Products</td>
        <td><v-checkbox v-model="entity.OnlyProducts" :color="(entity.OnlyProducts ? 'success' : 'secondary')"></v-checkbox></td>
      </tr>

      <tr>
        <td>Absolute Discount</td>
        <td><v-text-field
      label="Absolute Discount"
      solo-inverted
			prefix="£"
			type="number"
      flat
      :error-messages="serverErrors.filter(x => x.field === 'AbsoluteDiscount').map(x => x.error)"
      v-model="entity.AbsoluteDiscount"
      :rules="[DiscountTypeValidation]"
    ></v-text-field>
</td>
      </tr>

      <tr>
        <td>Percentage Discount</td>
        <td><v-text-field
      label="Percentage Discount"
      solo-inverted
			type="number"
      flat
      :error-messages="serverErrors.filter(x => x.field === 'PercentageDiscount').map(x => x.error)"
      v-model="entity.PercentageDiscount"
      :rules="[DiscountTypeValidation, validators.floatRange(entity.PercentageDiscount, 0, 1)]"
    ></v-text-field>
</td>
      </tr>

      <!-- <tr>
        <td>Single Use</td>
        <td><v-checkbox v-model="entity.SingleUse" :color="(entity.SingleUse ? 'success' : 'secondary')"></v-checkbox></td>
      </tr>

      <tr>
        <td>Single User Per Member</td>
        <td><v-checkbox v-model="entity.SingleUsePerMember" :color="(entity.SingleUsePerMember ? 'success' : 'secondary')"></v-checkbox></td>
      </tr> -->

      <tr>
        <td>Valid From</td>
        <td><DateField clearable v-model="entity.ValidFrom" :rules="[]" :error-messages="serverErrors.filter(x => x.field === 'ValidFrom').map(x => x.error)" /></td>
      </tr>

      <tr>
        <td>Valid To</td>
        <td><DateField clearable v-model="entity.ValidTo" :rules="[]" :error-messages="serverErrors.filter(x => x.field === 'ValidTo').map(x => x.error)" /></td>
      </tr>

      <!-- <tr>
        <td>Products</td>
        <td><v-text-field
      label="Products"
      solo-inverted
      flat
      :error-messages="serverErrors.filter(x => x.field === 'ProductIds').map(x => x.error)"
      v-model="entity.ProductIds"
      :rules="[]"
    ></v-text-field>
</td>
      </tr>

      <tr>
        <td>Order Types</td>
        <td><v-text-field
      label="Order Types"
      solo-inverted
      flat
      :error-messages="serverErrors.filter(x => x.field === 'OrderTypes').map(x => x.error)"
      v-model="entity.OrderTypes"
      :rules="[]"
    ></v-text-field>
</td>
      </tr>

      <tr>
        <td>User</td>
        <td><UserLookup v-model="entity.UserId" :rules="[]" :error-messages="serverErrors.filter(x => x.field === 'UserId').map(x => x.error)" /></td>
      </tr> -->


        </tbody>
      </v-simple-table>
      </v-form>

  </v-card>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils'
import * as Display from '@/datahelpers/displayTypes'
import * as FrameworkValidators from '@core/utils/validation'
import * as Validators from '@/datahelpers/validation'
import DateField from '@/views/helpers/DateField'

import UserLookup from '@/views/user/UserLookup'

import {
  mdiContentSaveOutline,
  mdiClose
} from '@mdi/js'
import { ref, watch, computed, expose } from '@vue/composition-api'

export default {
  components: {
    DateField,
    UserLookup
  },
  setup() {

    const { route, router  } = useRouter();

    const entity = computed(() => {
      return store.state.app.DiscountCode;
    });
    const loading = ref(false);
    const form = ref(null);
    const serverErrors = ref([]);
    const serverErrorMessage = ref(null);

    const fetchData = (id) => {
      store
        .dispatch('app/fetchDiscountCode', {
          id: id
        })
        .then(response => {
          loading.value = false;
        })
        .catch(error => {
          loading.value = false;
          console.log(error);
          router.push({ name: 'discountcode-list', params: { id: route.value.params.id } });
        })
    }

    // fetch the user information when params change
    //watch(() => route.value.params.id, (id) => fetchData(id));

    // and call now
    fetchData(route.value.params.id);


    const Save = function(e) {

      serverErrorMessage.value = null;
      serverErrors.value = [];

      const valid = form.value.validate();

      if (!valid) {
        return;
      }

      loading.value = true;

      store
        .dispatch('app/updateDiscountCode', {
          id: route.value.params.id,
          entity: entity.value
        })
        .then(response => {
          loading.value = false;
          router.push({ name: 'discountcode-view', params: { id: response.data.Id } });
        })
        .catch((error) => {
          loading.value = false;
          if (error.error) {
            if (error.message) serverErrorMessage.value = error.message;
            if (error.errors) serverErrors.value = error.errors
          } else {
            console.log(error);
          }
        });
    };

    const validators = {
      ...FrameworkValidators,
      ...Validators
    };

    const DiscountTypeValidation = (value) => {
      console.log("DiscountTypeValidation", value, entity)
      if (entity.value.AbsoluteDiscount && entity.value.PercentageDiscount) {
        return "You cannot specify both an absolute and percentage discount."
      }
      return true;
    }

    return {
      DiscountTypeValidation,
      form,
      Save,
      loading,
      entity,
      Display,
      icons: {
        mdiContentSaveOutline ,
        mdiClose
      },
      validators,
      serverErrorMessage,
      serverErrors
    };
  }
}
</script>

<style lang="scss" scoped>
.entity-edit::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td:last-child {
  padding-right: 0;
}
.entity-edit::v-deep .v-data-table th:last-child {
  width: 66%;
}
.entity-edit::v-deep  .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent;
}
.entity-edit::v-deep .v-input:not(.error--text) .v-messages {
  min-height: unset;
}
.entity-edit::v-deep .v-input .v-messages.error--text {
  margin-bottom: 8px;
}
.entity-edit::v-deep .v-input:not(.error--text) .v-text-field__details {
  min-height: unset;
  margin-bottom: 0;
}
.entity-edit::v-deep .v-input:not(.error--text) .v-input__slot {
  margin: 0;
  border-radius: 0;
}
.entity-edit::v-deep .v-input--selection-controls {
  margin: 10px;
}
</style>

